import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
		<path d="M5205 8374 c-201 -21 -465 -92 -633 -170 -40 -19 -74 -34 -77 -34 -2
0 -39 -20 -81 -45 -41 -25 -78 -45 -81 -45 -2 0 -40 -25 -84 -55 -386 -267
-667 -638 -813 -1070 -175 -515 -127 -1135 123 -1608 70 -133 95 -168 116
-163 11 2 82 14 158 27 114 18 144 20 176 10 71 -21 129 -115 116 -187 -5 -26
-1 -32 36 -54 54 -33 86 -82 100 -155 10 -48 19 -66 46 -88 48 -41 65 -80 62
-141 -3 -46 0 -54 30 -86 24 -25 52 -82 98 -200 35 -91 74 -176 86 -189 52
-56 129 -62 167 -14 28 36 25 61 -16 172 -94 247 -99 265 -67 259 17 -3 25
-20 130 -273 49 -116 64 -143 92 -162 19 -13 45 -23 58 -23 59 0 113 56 113
117 0 15 -38 106 -85 201 -47 95 -85 181 -85 192 0 13 7 20 19 20 14 0 35 -29
79 -112 159 -300 177 -331 207 -350 60 -36 124 -12 146 55 l13 40 -88 173
c-85 165 -97 204 -62 204 16 0 36 -32 132 -218 96 -186 122 -210 218 -200 78
9 126 63 126 142 0 41 -12 65 -109 223 -60 98 -119 196 -132 219 -80 136 -166
271 -184 286 -25 22 -42 25 -250 44 -161 14 -174 17 -250 53 -98 47 -166 114
-224 221 -59 108 -55 176 14 240 26 24 33 25 193 30 l167 5 -65 65 c-121 121
-192 140 -449 120 -205 -17 -252 -15 -298 7 -42 19 -88 64 -110 107 -41 78
-56 387 -28 566 36 220 118 424 245 608 58 85 74 105 169 206 63 67 16 34 -94
-67 -91 -83 -195 -195 -257 -277 -84 -112 -192 -303 -233 -414 -50 -132 -8
102 50 278 44 136 130 311 214 438 319 485 807 787 1381 858 118 14 366 12
485 -4 409 -57 769 -234 1090 -536 64 -60 72 -66 45 -30 -182 234 -437 438
-721 578 -169 84 -311 133 -504 174 -127 27 -161 30 -360 33 -121 2 -238 1
-260 -1z"/>
<path d="M3113 3532 c-10 -7 -268 -580 -275 -612 -2 -12 8 -15 52 -15 54 0 55
1 73 38 l19 37 142 0 142 0 18 -40 c17 -40 18 -40 72 -40 64 0 65 3 25 91 -16
35 -64 143 -106 239 -42 96 -88 201 -102 232 -28 63 -43 81 -60 70z m65 -332
c23 -58 42 -110 42 -115 0 -6 -39 -9 -97 -8 -112 2 -108 -5 -64 100 16 38 36
86 45 107 9 22 20 35 25 30 4 -5 26 -56 49 -114z"/>
<path d="M6356 3517 c-22 -32 -266 -589 -266 -605 0 -10 14 -12 53 -10 49 3
53 5 72 40 l20 38 142 0 143 0 17 -37 c16 -34 22 -38 62 -41 24 -2 49 -1 57 2
15 6 13 12 -61 176 -24 52 -60 133 -80 180 -20 47 -55 123 -76 168 -21 46 -39
88 -39 92 0 20 -31 18 -44 -3z m34 -210 c0 -7 20 -59 45 -116 25 -57 43 -106
41 -108 -2 -2 -47 -5 -100 -6 -74 -2 -96 1 -96 11 0 8 14 44 30 82 17 38 30
70 30 73 0 2 7 21 15 41 15 35 35 49 35 23z"/>
<path d="M7658 3522 c-8 -9 -52 -102 -98 -207 -46 -104 -93 -212 -105 -240
-53 -118 -65 -148 -65 -161 0 -10 15 -14 55 -14 42 0 55 3 55 15 0 8 4 15 9
15 5 0 12 11 15 25 l6 25 143 0 142 0 20 -38 c19 -35 23 -37 73 -40 66 -4 66
1 6 133 -25 55 -54 120 -64 145 -10 25 -34 79 -53 120 -19 41 -51 109 -70 151
-19 42 -39 79 -44 82 -6 4 -17 -1 -25 -11z m66 -304 c20 -48 39 -100 42 -115
l6 -28 -100 3 -100 3 40 97 c22 53 44 108 50 121 11 31 17 24 62 -81z"/>
<path d="M3760 3289 c0 -226 -4 -262 -34 -281 -20 -14 -63 -8 -89 12 -32 26
-35 25 -69 -7 -35 -34 -35 -45 2 -76 90 -76 236 -51 281 47 16 36 19 69 19
289 l0 247 -55 0 -55 0 0 -231z"/>
<path d="M4100 3210 l0 -310 55 0 55 0 0 310 0 310 -55 0 -55 0 0 -310z"/>
<path d="M4394 3495 c-3 -14 -3 -36 0 -50 6 -24 9 -25 91 -25 l85 0 0 -260 0
-260 55 0 55 0 2 262 3 262 80 0 80 1 3 48 3 47 -225 0 -226 0 -6 -25z"/>
<path d="M5382 3213 l3 -308 151 -3 c135 -2 158 0 210 19 115 43 184 138 192
265 7 105 -17 174 -83 240 -79 79 -130 94 -321 94 l-154 0 2 -307z m291 198
c120 -28 189 -165 141 -279 -18 -43 -58 -88 -96 -108 -35 -18 -207 -31 -216
-17 -4 6 -6 101 -4 212 l3 201 67 0 c37 0 84 -4 105 -9z"/>
<path d="M6800 3470 l0 -50 85 0 85 0 0 -260 0 -260 55 0 55 0 2 262 3 262 80
1 80 0 3 48 3 47 -225 0 -226 0 0 -50z"/>
<path d="M4192 2604 c-34 -23 -25 -55 22 -79 22 -11 41 -26 44 -33 6 -18 -27
-27 -59 -15 -30 12 -47 2 -26 -14 24 -20 63 -23 86 -7 33 24 24 55 -24 80 -52
27 -53 50 -2 47 40 -3 49 10 18 27 -26 13 -33 13 -59 -6z"/>
<path d="M4514 2607 c-3 -8 -4 -47 -2 -88 l3 -74 63 0 c35 0 62 4 62 10 0 6
-22 10 -48 11 -40 1 -47 4 -50 22 -3 20 1 22 37 22 31 0 41 4 41 16 0 12 -9
15 -37 12 -35 -3 -38 -1 -38 22 0 23 4 25 48 28 33 2 47 7 47 18 0 19 -118 20
-126 1z"/>
<path d="M4874 2607 c-10 -27 1 -160 14 -165 7 -2 12 5 12 17 0 29 55 30 70 1
6 -11 15 -18 21 -15 5 4 5 14 -1 26 -8 14 -7 25 5 41 21 32 19 55 -10 83 -27
28 -102 36 -111 12z m101 -57 c0 -33 -2 -35 -37 -38 l-38 -3 0 41 0 41 38 -3
c35 -3 37 -5 37 -38z"/>
<path d="M5238 2615 c-6 -7 62 -165 71 -165 5 1 64 120 79 161 2 5 -3 9 -11 9
-10 0 -23 -21 -35 -52 -22 -60 -40 -67 -56 -22 -14 41 -39 77 -48 69z"/>
<path d="M5614 2607 c-10 -27 1 -160 14 -165 9 -3 12 19 12 87 0 56 -4 91 -10
91 -6 0 -13 -6 -16 -13z"/>
<path d="M5925 2606 c-31 -13 -55 -51 -55 -85 0 -49 67 -89 118 -70 32 12 18
30 -18 23 -22 -5 -34 0 -54 19 -32 32 -32 42 0 73 21 22 31 25 60 20 41 -8 47
10 8 24 -14 6 -26 10 -27 10 -1 -1 -16 -7 -32 -14z"/>
<path d="M6243 2530 l0 -90 64 0 c49 0 63 3 63 15 0 11 -12 15 -50 15 -43 0
-50 3 -50 20 0 17 7 20 40 20 29 0 40 4 40 15 0 10 -10 14 -37 12 -35 -2 -38
0 -41 26 -3 26 -2 27 47 27 39 0 51 4 51 15 0 12 -14 15 -63 15 l-64 0 0 -90z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
